<template>
  <div>
    <v-row class="my-5" v-if="loading">
      <v-skeleton-loader
        class="mx-auto my-2 col-6 col-md-3 col-lg-4"
        max-width="300"
        type="image"
      ></v-skeleton-loader>
      <v-skeleton-loader
        class="mx-auto my-2 col-6 col-md-3 col-lg-4"
        max-width="300"
        type="image"
      ></v-skeleton-loader>
    </v-row>
    <v-row no-gutters class="">
      <div class="col-12">
        <h5 class="px-4">{{ title }}</h5>
      </div>
      <div class="col-12 col-md-6 shadow-md" v-for="(item, id) in items" :key="id">
        <v-row class="m-1 m-lg-2 bg-white justify-content-center shadow-lg">
          <picture
            class="col col-sm-5 col-lg-4 d-flex align-items-center justify-content-center p-0 listing-item-image"
          >
            <v-img
              aspect-ratio="1"
              :src="item.image.route"
              class="img-fluid"
              v-if="item.image && item.image.route != ''"
            />
          </picture>
          <div class="col col-sm-7 px-2" v-if="asociationPage">
            <h6 class="text-primary">{{ item.name + ' ' + item.lastname }}</h6>
            <p class="mb-1 lh-sm">{{ item.position }}</p>
            <p class="mb-0">{{ item.period }}</p>

            <v-row no-gutters v-if="!asociationPage && item.countries.length > 0">
              <img
                class="country__flag--directors"
                style="border-radius: 50%"
                :src="item.countries[0].flag"
                :alt="`País al que representa:' ${item.countries[0].name}`"
              />
            </v-row>
            <v-row no-gutters class="justify-content-end">
              <v-btn
                small
                text
                dark
                class="email-link"
                title="Contact"
                :href="`mailto:${extractContent(item.email)} `"
                ><v-icon dark> mdi-email </v-icon>
              </v-btn>
              <!-- <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                </template>
                <span>Contact</span>
              </v-tooltip> -->
            </v-row>
          </div>
          <div class="col col-sm-7 px-2" v-else>
            <h6 class="text-primary">{{ item.name + ' ' + item.lastname }}</h6>
            <p class="mb-1 lh-sm">{{ item.position }}</p>
            <v-row no-gutters class="mb-2 flex-nowrap">
              <v-icon>mdi-email</v-icon>
              <a
                class="email-link pl-2"
                :href="`mailto:${extractContent(item.email)} `"
                v-html="`${extractContent(item.email)}`"
              >
              </a>
            </v-row>
            <v-row no-gutters v-if="item.countries.length > 0">
              <img
                class="country__flag--directors"
                style="border-radius: 50%"
                :src="item.countries[0].flag"
                :alt="`País al que representa:' ${item.countries[0].name}`"
              />
            </v-row>
          </div>
        </v-row>
      </div>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'Directors',
    created() {},
    data() {
      return {}
    },
    props: {
      title: String,
      items: { type: Array },
      loading: { type: Boolean, required: false },
    },
    methods: {
      extractContent(html) {
        return new DOMParser().parseFromString(html, 'text/html').documentElement
          .textContent
      },
    },
    computed: {
      asociationPage() {
        if (this.$route.name === 'Asosiations') {
          return true
        } else return false
      },
    },
  }
</script>

<style lang="scss" scoped>
  p {
    font-size: 0.8rem;
  }
  .country__flag--directors {
    width: 35px;
    height: 35px;
  }
  .email-link {
    font-size: 0.8rem;
    color: inherit;
    word-break: break-word;
  }
  .img-fluid {
    max-height: 120px;
    max-width: 120px;
  }
</style>

import { render, staticRenderFns } from "./CompaniesCarousel.vue?vue&type=template&id=615b8588"
import script from "./CompaniesCarousel.vue?vue&type=script&lang=js"
export * from "./CompaniesCarousel.vue?vue&type=script&lang=js"
import style0 from "./CompaniesCarousel.vue?vue&type=style&index=0&id=615b8588&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <div class="main-wrapper">
    <!-- <title-bar title="About Us" subtitle="Explore your search places"></title-bar> -->
    <div class="content pt-0">
      <div class="about-wrapper">
        <div class="block-space bg-secondary">
          <div class="container">
            <div class="row row-grid align-items-center">
              <div class="col-12">
                <h2>POLÍTICAS DE PRIVACIDAD</h2>
                <p>
                  La presente Política de Privacidad establece los términos en que FIAF -
                  Federación Iberoamericana de Franquicias usa y protege la información
                  que es proporcionada por sus usuarios al momento de utilizar su sitio
                  web. Esta compañía está comprometida con la seguridad de los datos de
                  sus usuarios. Cuando le pedimos llenar los campos de información
                  personal con la cual usted pueda ser identificado, lo hacemos asegurando
                  que sólo se emplea de acuerdo con los términos de este documento. Sin
                  embargo esta Política de Privacidad puede cambiar con el tiempo o ser
                  actualizada por lo que le recomendamos y enfatizamos revisar
                  continuamente esta página para asegurarse que está de acuerdo con dichos
                  cambios.
                </p>
                <h3>Información que es recogida</h3>
                <p>
                  Nuestro sitio web podrá recoger información personal por ejemplo:
                  Nombre, información de contacto como su dirección de correo electrónica
                  e información demográfica. Así mismo cuando sea necesario podrá ser
                  requerida información específica para procesar algún pedido o realizar
                  una entrega o facturación.
                </p>
                <h3>Uso de la información recogida</h3>
                <p>
                  Nuestro sitio web emplea la información con el fin de proporcionar el
                  mejor servicio posible, particularmente para mantener un registro de
                  usuarios, de pedidos en caso que aplique, y mejorar nuestros productos y
                  servicios. Es posible que sean enviados correos electrónicos
                  periódicamente a través de nuestro sitio con ofertas especiales, nuevos
                  productos y otra información publicitaria que consideremos relevante
                  para usted o que pueda brindar algún beneficio, estos correos
                  electrónicos serán enviados a la dirección que usted proporcione y
                  podrán ser cancelados en cualquier momento.
                </p>
                <p>
                  FIAF - Federación Iberoamericana de Franquicias está altamente
                  comprometida para cumplir con el compromiso de mantener su información
                  segura. Usamos los sistemas más avanzados y los actualizamos
                  constantemente para asegurarnos que no exista ningún acceso no
                  autorizado.
                </p>
                <h3>Cookies</h3>
                <p>
                  Una cookie se refiere a un fichero que es enviado con la finalidad de
                  solicitar permiso para almacenarse en su ordenador, al aceptar dicho
                  fichero se crea y la cookie sirve entonces para tener información
                  respecto al tráfico web, y también facilita las futuras visitas a una
                  web recurrente. Otra función que tienen las cookies es que con ellas las
                  web pueden reconocerse individualmente y por tanto brindarte el mejor
                  servicio personalizado de su web.
                </p>
                <p>
                  Nuestro sitio web emplea las cookies para poder identificar las páginas
                  que son visitadas y su frecuencia. Esta información es empleada
                  únicamente para análisis estadístico y después la información se elimina
                  de forma permanente. Usted puede eliminar las cookies en cualquier
                  momento desde su ordenador. Sin embargo las cookies ayudan a
                  proporcionar un mejor servicio de los sitios web, estás no dan acceso a
                  información de su ordenador ni de usted, a menos de que usted así lo
                  quiera y la proporcione directamente . Usted puede aceptar o negar el
                  uso de cookies, sin embargo la mayoría de navegadores aceptan cookies
                  automáticamente pues sirve para tener un mejor servicio web. También
                  usted puede cambiar la configuración de su ordenador para declinar las
                  cookies. Si se declinan es posible que no pueda utilizar algunos de
                  nuestros servicios.
                </p>
                <h3>Enlaces a Terceros</h3>
                <p>
                  Este sitio web pudiera contener enlaces a otros sitios que pudieran ser
                  de su interés. Una vez que usted de clic en estos enlaces y abandone
                  nuestra página, ya no tenemos control sobre al sitio al que es
                  redirigido y por lo tanto no somos responsables de los términos o
                  privacidad ni de la protección de sus datos en esos otros sitios
                  terceros. Dichos sitios están sujetos a sus propias políticas de
                  privacidad por lo cual es recomendable que los consulte para confirmar
                  que usted está de acuerdo con estas.
                </p>
                <h3>Control de su información personal</h3>
                <p>
                  En cualquier momento usted puede restringir la recopilación o el uso de
                  la información personal que es proporcionada a nuestro sitio web. Cada
                  vez que se le solicite rellenar un formulario, como el de alta de
                  usuario, puede marcar o desmarcar la opción de recibir información por
                  correo electrónico. En caso de que haya marcado la opción de recibir
                  nuestro boletín o publicidad usted puede cancelarla en cualquier
                  momento.
                </p>
                <p>
                  Esta asociación no venderá, cederá ni distribuirá la información
                  personal que es recopilada sin su consentimiento, salvo que sea
                  requerido por un juez con una orden judicial. Se reserva el derecho de
                  cambiar los términos de la presente Política de Privacidad en cualquier
                  momento.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ParallaxSection from 'Components/FrontendComponents/ParallaxSection'
  import CompaniesCarousel from 'Components/FrontendComponents/CompaniesCarousel'
  import FormerSecretaries from 'Components/FrontendComponents/FormerSecretaries'
  import Directors from 'Components/FrontendComponents/Directors.vue'
  export default {
    name: 'About',
    data() {
      return {
        directors: [],
        secretaries: [],
        loadingDirectors: false,
        loadingFormerSecretaries: false,
      }
    },
    components: {
      ParallaxSection,
      CompaniesCarousel,
      FormerSecretaries,
      Directors,
    },
    created() {
      this.getDirectors()
      this.getFormerSecretaries()
    },
    methods: {
      async getDirectors() {
        this.loadingDirectors = true
        axios
          .get('directors?order=order&by=asc&company_id=null&type=1&limit=10')
          .then((response) => {
            this.directors = response.data
            this.loadingDirectors = false
          })
      },
      async getFormerSecretaries() {
        this.loadingFormerSecretaries = true
        axios
          .get('directors?order=order&by=asc&company_id=null&type=2&limit=10')
          .then((response) => {
            this.secretaries = response.data
            this.loadingFormerSecretaries = false
          })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .listing-item-container.list-layout .listing-item {
    width: 100%;
    height: 200px;
  }
</style>

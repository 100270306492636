<template>
  <div class="block-space">
    <swiper :options="swiperOption" v-if="companies.length == 0">
      <swiper-slide v-for="(item, index) in 12" :key="index">
        <v-skeleton-loader class="px-4 item-img" type="image"></v-skeleton-loader>
      </swiper-slide>
    </swiper>

    <swiper :options="swiperOption" v-else>
      <swiper-slide v-for="(item, index) in companies" :key="index">
        <a :href="'/asociations/' + item.link" class="item-img-container">
          <v-img
            class="item-img"
            :aspect-ratio="1"
            :title="item.name"
            v-if="item.logo && item.logo.route != ''"
            :src="item.logo.route"
            :alt="item.name"
          />
        </a>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
  import Slick from 'vue-slick'
  export default {
    components: {
      Slick,
    },
    data() {
      return {
        companies: [],
        swiperOption: {
          speed: 300,
          loop: true,
          centerInsufficientSlides: true,
          autoplay: {
            delay: 3000,
          },
          breakpoints: {
            1440: {
              slidesPerView: 10,
              spaceBetween: 30,
            },
            1025: {
              slidesPerView: 8,
              spaceBetween: 30,
            },
            768: {
              slidesPerView: 6,
              spaceBetween: 30,
            },
            576: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            320: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
          },
        },
      }
    },
    created() {
      this.getCompanies()
    },
    methods: {
      async getCompanies() {
        axios
          .get('companies-list?type=1&active=1&limit=30')

          .then((response) => {
            this.companies = response.data
            if (this.companies.length == 0) {
              this.boilerplateActive = true
            }
          })
      },
    },
  }
</script>
<style lang="css">
  .item-img-container {
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1);
    transition: transform 0.5s ease-out;
  }
  .item-img-container:hover {
    transform: scale(1.15);
    transition: transform 0.5s ease-out;
  }
  .item-img {
    max-width: 200px;
    max-height: 200px;
    margin: 10px 0;
  }
</style>
